import { Controller } from "stimulus";
import  stylesArray  from "./map_styles";

import debounce from 'config/debounce.js';

export default class extends Controller {
  connect() {
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    let contactSectionElement = document.querySelector('.section-contact')
    window.initMap = this.constructMap.bind(this)

    this.googleMapsApiKey = contactSectionElement.dataset.googleMapsApiKey
    this.appendScript()
    this.pinLocations = JSON.parse(contactSectionElement.dataset.mapPins)
    this.monacoCenterCoordinates = {lat: 43.7432538, lng: 7.4358248}


    this.initLogoAnimation()
  }

  appendScript() {
    if (document.querySelector('#interactive-map')) {
      if (this.scriptAppended()) {
        this.constructMap()
      } else {
        let googleMapScript = document.createElement('script')

        googleMapScript.type = 'text/javascript'
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&libraries=places,geometry&callback=initMap`

        document.body.appendChild(googleMapScript)
      }
    }
  }

  constructMap() {
    let map = this.createMap()

    let markers = this.pinLocations.map((location) => {
      return this.createMarkers(location, map)
    })

    markers.forEach((marker) => {
      let hrefAttribute = `href=${marker.url}`;

      if (marker.url === "#") {
        hrefAttribute = "";
      }

      let content = `<div class='google_info_window'>
        <a style="text-decoration: none;" target="_blank" rel="noopener noreferrer" ${hrefAttribute}>
          <h3 class='google_pin_title' > ${marker.title} </h3>
        </a>
        </div>`

      let infoWindow = new google.maps.InfoWindow()

      infoWindow.setContent(content)

      infoWindow.open(map, marker)
    })

    map.setOptions({styles: stylesArray()})
  }

  createMap() {
    return new google.maps.Map(document.getElementById('interactive-map'), {
      center: this.monacoCenterCoordinates,
      draggable: true,
      geocoder: new google.maps.Geocoder(),
      zoomControl: true,
      zoom: 16,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    })
  }

  createMarkers(location, map) {
    let icon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0
    }

    if (location.title === "Le Renzo") {
      icon = {
        anchor: new google.maps.Point(275, 150),
        fillColor: '#bacdd9',
        fillOpacity: 1,
        path: document.querySelector('.le-renzo-svg-public svg path').getAttribute('d'),
        scale: 0.18,
        rotation: -70
      }
    }

    let marker = new google.maps.Marker({
      icon: icon,
      phone: location.phone,
      position: {lat: Number(location.latitude), lng: Number(location.longitude)},
      map: map,
      streetAddress: location.streetAddress,
      title: location.title,
      url: location.link
    })

    if (location.title === "Le Renzo") {
      let scale = 0.2;
      let previousZoom = 15;


      google.maps.event.addListener(marker, 'mouseover', function(){
        marker.setOptions({'opacity': 0.7})
      });

      google.maps.event.addListener(marker, 'mouseout', function(){
        marker.setOptions({'opacity': 1})
      });

      google.maps.event.addListener(map, 'zoom_changed', () => {
        let zoom = map.getZoom();

        if (zoom > previousZoom) {
          // zoomed in
          scale = (scale + 0.07);
        } else {
          // zoomed out
          scale = (scale - 0.07);
        }

        previousZoom = zoom

        let iconResize = {
          anchor: icon.anchor,
          fillColor: '#bacdd9',
          fillOpacity: 1,
          path: document.querySelector('.le-renzo-svg-public svg path').getAttribute('d'),
          scale: scale,
          rotation: -70
        }

        marker.setOptions({icon: iconResize})

      })
    }

    return marker;
  }

  scriptAppended() {
    return !!document.querySelector('script[src*="https://maps.googleapis.com/maps/api/"]')
  }



  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
          targetElement.parentElement.classList.remove('white-logo')
          targetElement.parentElement.classList.add('color-logo')
          document.querySelector('.nav-trigger').classList.remove('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }
}
