import { Controller } from "stimulus";

import debounce from 'config/debounce.js';

const videoTimer = 2000

export default class extends Controller {
  static targets = ['video', 'playBtn', 'fullScreenIcon']
  connect() {
    const el = this.element
    this.mobileBreakPoint = 767
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    this.timer = null
    this.videoTarget.addEventListener('play',  () => el.classList.add('playing'))
    this.videoTarget.addEventListener('pause', () => el.classList.remove('playing'))
    this.videoTarget.addEventListener('mousemove', this.mousemove.bind(this))
    this.videoTarget.addEventListener('ended', this.ended.bind(this))
    this.playBtnTarget.addEventListener('mouseenter', this.clearHiddenControls.bind(this))
    this.playVideoWhenScrolled()
    this.initLogoAnimation()
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimationOnScroll();
      }, 500)
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimationOnScroll() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if (targetTop + targetHeight < sectionTop) {
          // ABOVE
        } else if (targetTop > sectionTop + sectionHeight) {
          // BELOW
        } else {
          // OVERLAPPING
          targetElement.parentElement.classList.remove('color-logo')
          targetElement.parentElement.classList.add('white-logo')
          document.querySelector('.nav-trigger').classList.add('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }

  isMobile() {
    return this.clientWidth < this.mobileBreakPoint
  }

  playVideoWhenScrolled() {
    if (this.intersectionObserverIsSupported()) {
      setTimeout(() => {
        this.playVideoInViewport();
      }, 500);

    }
  }

  playVideoInViewport() {
    if (this.isDesktop() && this.isHomePage()) {
      this.playVideoObserver = new IntersectionObserver(this.playVideo.bind(this), {threshold: .1});
      this.playVideoObserver.observe(this.videoTarget);
    }
  }

  playVideo(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let videoElement = entry.target
        videoElement.loop = true
        videoElement.play()
        this.playVideoObserver.unobserve(videoElement);
        this.fullScreenIconTarget.classList.add('faded-in');
      }
    })
  }

  isHomePage() {
    return ['/en/', '/fr/'].includes(window.location.pathname)
  }

  ended() {
    this.clearHiddenControls()
    this.videoTarget.load()
  }

  clearHiddenControls() {
    clearTimeout(this.timer)
    this.element.classList.remove('hidden-controls')
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  fullScreen(event) {
    let videoElement = event.target.nextElementSibling;

    if (videoElement.mozRequestFullScreen) {
      videoElement.mozRequestFullScreen()
    } else if (videoElement.webkitRequestFullScreen) {
      videoElement.webkitRequestFullScreen()
    } else if (videoElement.webkitEnterFullscreen) {
      videoElement.webkitEnterFullscreen()
    }
  }

  mousemove(e) {
    this.clearHiddenControls()
    if (this.videoTarget.paused) return
    this.timer = setTimeout(() => {
      this.element.classList.add('hidden-controls')
    }, videoTimer)
  }

  togglePlay(event) {
    event.preventDefault()
    this.videoTarget.paused ? this.videoTarget.play() : this.videoTarget.pause()
    this.fullScreenIconTarget.classList.add('faded-in');
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }
}
