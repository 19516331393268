import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {

  connect() {
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    const element = this.element
    let carousel = element.querySelector('.js-carousel-glide')

    if (carousel) {
      new Glide(carousel, {type: "carousel"}).mount()
    }

  }
}
