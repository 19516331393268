import { Controller } from "stimulus";

import debounce from 'config/debounce.js';

export default class extends Controller {
  static targets = ['playBtn', 'video', 'fullScreenIcon']

  connect() {
    const element = this.element
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth
    this.videoTarget.addEventListener('play',  () => element.classList.add('playing'))
    this.videoTarget.addEventListener('pause', () => element.classList.remove('playing'))
    this.runAutoplayVideo();

    this.initLogoAnimation()
  }

  fullScreen(event) {
    let videoElement = event.target.nextElementSibling;

    if (videoElement.mozRequestFullScreen) {
      videoElement.mozRequestFullScreen()
    } else if (videoElement.webkitRequestFullScreen) {
      videoElement.webkitRequestFullScreen()
    } else if (videoElement.webkitEnterFullscreen) {
      videoElement.webkitEnterFullscreen()
    }
  }

  runAutoplayVideo() {
    if (this.videoTarget.classList.contains('js-autoplay-video')) {
      this.videoTarget.play()
    }
  }

  togglePlay(event) {
    event.preventDefault()
    this.videoTarget.paused ? this.videoTarget.play() : this.videoTarget.pause()
    this.fullScreenIconTarget.classList.add('faded-in');
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    if (getComputedStyle(this.element, null).display !== 'none') {
      let alterLogoStyleDebounce = debounce(
        () => {
          let targetRect = targetElement.getBoundingClientRect();
          let sectionRect = sectionElement.getBoundingClientRect();

          let targetTop = targetRect.top + document.body.scrollTop
          let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

          let sectionTop = sectionRect.top + document.body.scrollTop
          let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

          if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
            targetElement.parentElement.classList.remove('white-logo')
            targetElement.parentElement.classList.add('color-logo')
            document.querySelector('.nav-trigger').classList.remove('nav-white')
          }
        }
      )

      window.addEventListener(
        "scroll", () => {
          alterLogoStyleDebounce()
        }
      )
    }
  }

}
