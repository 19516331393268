var getSiblings = function (element) {

	// Setup siblings array and get the first sibling
	var siblings = [];
	var sibling = element.nextElementSibling

	// Loop through each sibling and push to the array
	while (sibling) {
		if (sibling.nodeType === 1 && sibling !== element) {
			siblings.push(sibling);
		}
		sibling = sibling.nextElementSibling
	}

	return siblings;

};

export default getSiblings;
