class imageOutEvent {
  constructor($image, $gradient) {
    this.props = {
      $image,
      $gradient
    };
    this.init();
  }
  getWindowCenter() {
    return window.pageYOffset + (window.outerHeight * 0.5);
  }
  getImageCenter() {
    let {$image} = this.props;
    return window.pageYOffset + $image.getBoundingClientRect().top + ($image.offsetHeight * 0.5);
  }
  getImageDistanceFromCenter() {
    return this.getWindowCenter() - this.getImageCenter();
  }
  getWindowHalfHeight() {
    return window.outerHeight * 0.5;
  }
  init() {
    let {$gradient} = this.props;
    window
      .addEventListener( 'scroll', event => {
        let threshold = window.screen.width < 768 ? .8 : .9;
        let distance = this.getImageDistanceFromCenter();
        let halfHeight = this.getWindowHalfHeight();
        if (distance > halfHeight * threshold) {
          $gradient.classList.add('faded-in');;
        } else {
          $gradient.classList.remove('faded-in');
        }
      })
    ;
  }
}

export class AnimateIn {
  constructor(selector) {
    this.selectorElement = this.setSelector(selector)
    this.fadeInElements = this.getFadeInElements();
    this.fadeUpElements = this.getFadeUpElements();
    this.imageOutElements = this.getImageOutElements();
    let origin = window.location.origin;
    this.gradientImages = [
      `${origin}/images/gradients/Gradient_02.jpg`,
      `${origin}/images/gradients/Gradient_03.jpg`,
      `${origin}/images/gradients/Gradient_04.jpg`,
      `${origin}/images/gradients/Gradient_05.jpg`,
      `${origin}/images/gradients/Gradient_06.jpg`,
      `${origin}/images/gradients/Gradient_07.jpg`,
      `${origin}/images/gradients/Gradient_08.jpg`,
      `${origin}/images/gradients/Gradient.jpg`
    ];
    this.scrollPosition = 0;
    this.isScrollingDown = true;
  }

  init() {
    if (this.intersectionObserverIsSupported()) {
      this.handleScrollDirection();

      setTimeout(() => {
        this.setImageOutAnimations();
        this.setFadeInAnimations();
        this.setFadeUpAnimations();
        this.setFadeUpGradientAnimations();
      }, 500);

    } else {
      this.handleNoIntersectionObserverSupport();
    }
  }

  getFadeInElements() {
    if (this.selectorElement) {
      return this.selectorElement.querySelectorAll('.js-fadeIn')
    } else {
      return document.querySelectorAll('.js-fadeIn');
    }
  }

  getFadeUpElements() {
    if (this.selectorElement) {
      return this.selectorElement.querySelectorAll('.js-fadeUp');
    } else {
      return document.querySelectorAll('.js-fadeUp');
    }
  }

  getImageOutElements() {
    if (this.selectorElement) {
      return this.selectorElement.querySelectorAll('.js-imageOut');
    } else {
      return document.querySelectorAll('.js-imageOut');
    }
  }

  setImageOutAnimations() {
    let {imageOutElements} = this;

    if (imageOutElements.length > 0) {
      this.addGradientElements();

      for (let imageOutElement of imageOutElements) {
        new imageOutEvent(imageOutElement, imageOutElement.previousElementSibling);
      }
    }
  }

  setFadeInAnimations() {
    let {fadeInElements} = this;
    let options = {
      root: null,
      rootMargin:'0px',
      threshold: .5
    };

    if (fadeInElements.length > 0) {
      this.fadeInObserver = new IntersectionObserver(this.animateFadeIn.bind(this), options);
      for (let fadeInElement of fadeInElements) {
        this.fadeInObserver.observe(fadeInElement);
      }
    }
  }

  setFadeUpAnimations() {
    let {fadeUpElements} = this;
    let options = {
      root: null,
      rootMargin:'0px',
      threshold: .2
    };

    if (fadeUpElements.length > 0) {
      this.fadeUpObserver = new IntersectionObserver(this.animateFadeUp.bind(this), options);
      for (let fadeUpElement of fadeUpElements) {
        this.fadeUpObserver.observe(fadeUpElement);
      }
    }
  }

  setFadeUpGradientAnimations() {
    let fadeUpGradientElements = document.querySelectorAll('.js-gradient');
    let options = {
      root: null,
      rootMargin:'0px',
      threshold: .4
    };

    this.fadeUpGradientObserver = new IntersectionObserver(this.animateFadeUpGradient.bind(this), options);
    for (let fadeUpGradientElement of fadeUpGradientElements) {
      this.fadeUpGradientObserver.observe(fadeUpGradientElement);
    }
  }

  setSelector(selector) {
    document.querySelector(selector)
  }

  animateFadeIn(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('faded-in');
        this.fadeInObserver.unobserve(entry.target);
      }
    });
  }

  animateFadeUp(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('faded-up');
        this.fadeUpObserver.unobserve(entry.target);
      }
    });
  }

  animateFadeUpGradient(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.target.nextSibling.classList.contains('js-fadeUp')) {
        entry.target.classList.add('js-animateFadeUp');
        this.fadeUpGradientObserver.unobserve(entry.target);
      }
    });
  }

  animateImageOut(entries, observer) {
    entries.forEach(entry => {
      let gradientImage = entry.target.previousElementSibling;
      if (this.isScrollingDown && entry.boundingClientRect.y < 0) {
        gradientImage.classList.add('faded-in');
      } else {
        gradientImage.classList.remove('faded-in');
      }
    });
  }

  addGradientElements() {
    let imageOutElements = this.imageOutElements;
    let gradientImages = [...this.randomizeGradientElements()];
    let fadeUpGradientElements = document.querySelectorAll('.js-gradient');

    for (let imageOutElement of imageOutElements) {
      // Create Gradient
      var gradient = document.createElement('div');
      gradient.classList.add('js-gradient');

      if (gradientImages.length === 0) {
        gradientImages = [...this.gradientImages];
      }

      gradient.style.backgroundImage = `url('${gradientImages.shift()}')`;

      if (imageOutElement.classList.contains('js-fadeUp')) {
        gradient.setAttribute('style', `${gradient.getAttribute('style')} transform: translateY(10%);`);
      }

      // fixes broken Glide js carousel by not wrapping the
      // gradient in a div
      if (imageOutElement.classList.contains('js-slider-case')) {
        imageOutElement.parentNode.insertBefore(gradient, imageOutElement);
      } else {
        var wrapper = document.createElement('div');

        wrapper.style.position = 'relative';
        imageOutElement.parentNode.insertBefore(wrapper, imageOutElement);
        wrapper.appendChild(gradient);
        wrapper.appendChild(imageOutElement);
      }
    }
  }

  randomizeGradientElements() {
    return this.gradientImages.sort(() => Math.random() - 0.5);
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  handleNoIntersectionObserverSupport () {
    let {fadeInElements, fadeUpElements} = this;

    for (let fadeInElement of fadeInElements) {
      fadeInElement.classList.add('faded-in');
    }
    for (let fadeUpElement of fadeUpElements) {
      fadeUpElement.classList.add('faded-up');
    }
  }

  handleScrollDirection() {
    window.addEventListener('scroll', () => {
      if ((document.body.getBoundingClientRect()).top > this.scrollPosition) {
        this.isScrollingDown = false;
      } else {
        this.isScrollingDown = true;
      }
      this.scrollPosition = (document.body.getBoundingClientRect()).top;
    });
  }
}

let animate = new AnimateIn();

document.addEventListener('DOMContentLoaded', () => {
  animate.init();
});
