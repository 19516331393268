import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

import debounce from 'config/debounce.js';

export default class extends Controller {
  static targets = ['nextSlide', 'previousSlide']

  connect() {
    const element = this.element
    let carousel = element.querySelector('.js-carousel-glide')

    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    if (carousel) {
      let glide = new Glide(carousel, {
        type: "slider",
        rewind: false,
        bound: true,
        perView: 3.6
      }).mount()

      glide.on('run.end', () => {
        this.nextSlideTarget.classList.add('timeline-disable-button')
      })

      glide.on('run.start', () => {
        this.previousSlideTarget.classList.add('timeline-disable-button')
      })

      glide.on('run', () => {
        this.nextSlideTarget.classList.remove('timeline-disable-button')
        this.previousSlideTarget.classList.remove('timeline-disable-button')
      })

      this.previousSlideTarget.classList.add('timeline-disable-button')
    }


    this.initLogoAnimation()
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
          targetElement.parentElement.classList.remove('white-logo')
          targetElement.parentElement.classList.add('color-logo')
          document.querySelector('.nav-trigger').classList.remove('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }

}
