import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  static targets = ['modal','lightbox', 'index'];

  state = {
    lightbox:{}
  }

  connect() {
    let swipe = new Glide('.slide-glide', {
      type: 'slider',
      perView: 2.3,
      rewind: false,
      bound: true,
      breakpoints: {
        1023: {
          perView: 1.2,
          gap: 20
        },
        767: {
          perView: 1.2,
          gap: 20
        }
      }
    })

    swipe.mount()

    this.initLightBox();
  }

  initLightBox() {
    this.state.lightbox = new Glide(this.lightboxTarget, {
      type: "carousel",
      gap: 0,
      perView: 1,
      animationDuration: 0
    }).mount();

    this.state.lightbox.on(['run.after','move.after'], (event) => {
      for (let indexTarget of this.indexTargets) {
        indexTarget.textContent = this.state.lightbox.index + 1;
      }
    });

    this.state.lightbox.on('run.after', () => {
      this.openLightBox();
    });

    window.addEventListener('keyup', event => {
      if (event.keyCode == 27) {
        this.closeLightBox();
      }
    });
  }

  revealLightboxOpener(event) {
    event.currentTarget.querySelector('.js-fullScreen').classList.add('reveal')
  }

  hideLightboxOpener(event) {
    event.currentTarget.querySelector('.js-fullScreen').classList.remove('reveal')
  }

  openLightBox() {
    let lightBoxModal = this.modalTarget;
    lightBoxModal.classList.add('show');
  }

  closeLightBox() {
    let lightBoxModal = this.modalTarget;
    lightBoxModal.classList.remove('show');
  }

  nextSlide() {
    let {lightbox} = this.state;
    lightbox.go(`>`);
  }

  previousSlide() {
    let {lightbox} = this.state;
    lightbox.go(`<`);
  }

  goToSlide(event) {
    let {lightbox} = this.state;
    let element = event.currentTarget;
    let index = element.dataset.index;
    lightbox.go(`=${index}`);
  }

}
