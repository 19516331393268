import { Controller } from "stimulus";

import debounce from 'config/debounce.js';

export default class extends Controller {

  connect() {
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    this.initLogoAnimation()
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
          targetElement.parentElement.classList.remove('white-logo')
          targetElement.parentElement.classList.add('color-logo')
          document.querySelector('.nav-trigger').classList.remove('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }
}
