import { Controller } from "stimulus";

export default class extends Controller {


  connect() {
    document.querySelector('body').classList.remove('notransition');
  }

}
