import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['menu', 'hamburger', 'imageLogo', 'textLogo']
  connect() {
    this.blockTransition()
    let options = {
      threshold: 0.1
    }

    if (this.intersectionObserverIsSupported()) {
      // sorry old browsers you won't see the beautiful logo animation
      let observer = new IntersectionObserver(this.animate.bind(this), options);
      let introElements = document.querySelectorAll('.intro');

      for (let intro of introElements) {
        observer.observe(intro);
      }
    } else {
      this.element.classList.add('scrolled');
    }

    this.handleTextLogo();
  }

  animate(entries, observer) {
    entries.forEach(entry => {
      let computedStyle = getComputedStyle(entry.target);
      let isHidden = computedStyle.display === 'none';
      let isShown = !isHidden;

      if (entry.intersectionRatio < 0.1 && isShown) {
        // not in view port
        this.element.classList.add('scrolled');

        // trigger event that intro is not in viewport
        const event = new CustomEvent("intro-out-viewport");
        this.element.dispatchEvent(event);
      } else {
        // in view port
        this.element.classList.remove('scrolled');

        // trigger event that intro is in viewport
        const event = new CustomEvent("intro-in-viewport");
        this.element.dispatchEvent(event);
      }
    })
  }

  blockTransition() {
    clearTimeout(this.timer);
    document.body.classList.add("notransition");
    this.timer = setTimeout(() => {
      document.body.classList.remove("notransition");
    }, 500);
  }

  toggleMenu(e) {
    e.preventDefault()
    this.hamburgerTarget.classList.toggle('nav-trigger--active')
    this.menuTarget.classList.toggle('active')
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  handleTextLogo() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        this.element.classList.add('swapped-logo');
      } else {
        this.element.classList.remove('swapped-logo')
      }
    });
  }
}
