import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

import debounce from 'config/debounce.js';

export default class extends Controller {

  connect() {
    const element = this.element

    new Glide(element, {type: "carousel"}).mount()
  }

}
