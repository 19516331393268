import { Controller } from "stimulus";
import { getBreakpoint } from "init/breakpoint";

export default class extends Controller {

  connect() {
    window.addEventListener("load", this.load.bind(this), true);
    this.element.classList.add('js-fadeUp')
  }

  load() {
    (getBreakpoint() === "mobile") ? this.open() : this.desktopAnimation()
  }

  desktopAnimation() {
    const header = this.application.controllers.find(c => c.identifier == 'header')
    const $this = this
    if (header.element.classList.contains('scrolled')) {
      this.open()
    } else {
      setTimeout(() => {
        header.element.addEventListener('intro-out-viewport', this.open.bind($this))
      }, 700)
    }
  }

  open() {
    this.element.classList.add('faded-up')
  }

  close(event) {
    event.preventDefault()

    const element = this.element
    element.classList.add('js-fadeDown')
    setTimeout(() => element.classList.add('js-collapse'), 500);
  }

}
