import { Controller } from "stimulus";
import { submitForm } from "init/ajax";
import { formParams } from "init/forms";

import debounce from 'config/debounce.js';

export default class extends Controller {
  static targets = ['scrollUp']
  connect() {
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    this.initLogoAnimation()
  }

  scrollToTop(event) {
    event.preventDefault();
    this.scrollTo(document.documentElement, 0, 1250);
    this.initLogoAnimation()
  };

  scrollTo(element, to, duration) {
    let start = element.scrollTop;
    let change = to - start;
    let currentTime = 0;
    let increment = 20;

    let animateScroll = () => {
        currentTime += increment;
        let val = this.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
  }

  async submit(event) {
    event.preventDefault();

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      await submitForm(form);

      let thankyou = document.createElement('div')
      thankyou.innerHTML = "Thank you!"
      thankyou.classList.add('footer__subscribe-thank-you')

      document.querySelector('.footer__subscribe-controls').append(thankyou)
      document.querySelector(".footer__subscribe-submit button").disabled = true;
    }
  }

  easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
          targetElement.parentElement.classList.remove('white-logo')
          targetElement.parentElement.classList.add('color-logo')
          document.querySelector('.nav-trigger').classList.remove('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }

}
