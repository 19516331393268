import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['close']

  connect() {
    setTimeout(() => {
      this.cookieIsAccepted() ? this.removeToast() : this.showToast();
    }, 1000)
  };

  writeAcceptCookie() {
    document.cookie = `gdprAccepted=true; ${this.cookieExpiryString()}`;
  };

  clearAcceptCookie() {
    document.cookie = "gdprAccepted=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }

  cookieIsAccepted() {
    return document.cookie.split(";").filter(c => c.trim().startsWith("gdprAccepted=")).length != 0;
  }

  showToast() {
    this.element.classList.add('show');
  }

  removeToast() {
    this.writeAcceptCookie();
    this.element.classList.remove('show');
  }

  cookieExpiryString() {
    const exdays = 30;
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

    return `expires=${d.toUTCString()}; path=/`;
  };

}