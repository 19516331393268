import { Controller } from "stimulus";
import { CountUp } from "countup.js";

export default class extends Controller {
  connect() {
    this.elementsWithNumbers = Array.from(this.element.querySelectorAll('.js-animate-number'));
    this.fadeUpFactsFiguresElements = this.getFadeUpFactsFiguresElements();
    this.init()
  }


  getFadeUpFactsFiguresElements() {
    return document.querySelectorAll('.js-fadeUpFactsFigures');
  }

  init() {
    let options = {
      root: null,
      threshold: .5
    };

    if (this.elementsWithNumbers.length > 0) {
      this.animateNumbersObserver = new IntersectionObserver(this.animateNumbers.bind(this), options);
      for (let element of this.elementsWithNumbers) {
        this.animateNumbersObserver.observe(element);
      }
    }


    if (this.intersectionObserverIsSupported()) {
      this.handleScrollDirection();

      setTimeout(() => {
        this.setFadeUpFactsFiguresAnimations();
      }, 500);
    } else {
      this.handleNoIntersectionObserverSupport();
    }
  }

  handleScrollDirection() {
    window.addEventListener('scroll', () => {
      if ((document.body.getBoundingClientRect()).top > this.scrollPosition) {
        this.isScrollingDown = false;
      } else {
        this.isScrollingDown = true;
      }
      this.scrollPosition = (document.body.getBoundingClientRect()).top;
    });
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  handleNoIntersectionObserverSupport () {
    let {fadeUpFactsFiguresElements} = this;

    for (let fadeInElement of fadeUpFactsFiguresElements) {
      fadeInElement.classList.add('faded-in');
    }
    for (let fadeUpElement of fadeUpFactsFiguresElements) {
      fadeUpElement.classList.add('faded-up');
    }
  }

  animateNumbers(entries, observer) {
    entries.forEach((entry) => {
      let numberSpan = entry.target.querySelector('span')

      if (entry.isIntersecting && numberSpan && !numberSpan.classList.contains('js-counted')) {
        let countUp = new CountUp(
                        numberSpan,
                        Number(numberSpan.innerHTML.replace(",", "")), {
                          duration: 5
                        }
                      );
        countUp.start();

        numberSpan.classList.add('js-counted')

        this.animateNumbersObserver.unobserve(numberSpan);
      }
    })
  }

  setFadeUpFactsFiguresAnimations() {
    let {fadeUpFactsFiguresElements} = this;
    let options = {
      root: null,
      rootMargin:'0px',
      threshold: .5
    };

    if (fadeUpFactsFiguresElements.length > 0) {
      this.fadeUpObserver = new IntersectionObserver(this.animateFadeUpFactsFigures.bind(this), options);
      for (let fadeUpElement of fadeUpFactsFiguresElements) {
        this.fadeUpObserver.observe(fadeUpElement);
      }
    }
  }

  animateFadeUpFactsFigures(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('faded-up');
        this.fadeUpObserver.unobserve(entry.target);
      }
    });
  }
}
