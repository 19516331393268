// This can be removed if we drop IE11 support
import fetch from "unfetch";

import { formParams } from "./forms";

export function fetchWithCSRF(url, options = {}) {
  options.headers || (options.headers = {});

  const token = csrfToken();
  if (token) {
    options.headers["X-CSRF-Token"] = token;
  }

  return fetch(url, options);
}

function csrfToken() {
  const meta = document.querySelector("meta[name=csrf-token]");
  return meta && meta.content;
}

// Submit a form via AJAX and return HTML
export async function submitForm(form) {
  const formData = formParams(form);
  const response = await fetchWithCSRF(form.action, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  });
  const json = await response.json();

  let html;
  if (json.redirect_to) {
    location.href = json.redirect_to;
    html = "";
  } else if (json.html) {
    html = document.createRange().createContextualFragment(json.html);
  } else {
    html = json;
  }

  return html;
}

export async function loadRequestHtmlBy(url) {
  const response = await fetchWithCSRF(url);
  const htmlText = await response.text();

  return document.createRange().createContextualFragment(htmlText);
}
