import Panzoom from '@panzoom/panzoom'
import { Controller } from "stimulus";

import debounce from 'config/debounce.js';
import getSiblings from 'config/next_siblings';

export default class extends Controller {
  static targets = ['container', 'mobileZoomContainer']


  connect() {
    this.mobileBreakPoint = 767
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    this.zoomingInLevels = ['zoomed-in-first', 'zoomed-in-second']
    this.zoomingOutLevels = ['zoomed-in-second', 'zoomed-in-first']

    this.markers = document.querySelectorAll('.js-markerFadeIn')
    this.labelLayer = document.querySelector('.js-labelLayersFadeIn')

    this.initAnimations();

    this.initPanZoom();

    if (this.isMobileOrTablet()) {
      let siblings = getSiblings(this.element).concat(
        document.querySelector('footer'),
        document.querySelector('.page-nav')
      )

      siblings.forEach((siblingElement) => {
        siblingElement.setAttribute('style', 'transition: transform .5s; transform: translateY(0%);')
      })
    }

    this.initLogoAnimation()
  }

  closeAndRevealInfoBox() {
    this.infoBoxSwitchOut = true
    this.infoBoxElement.classList.remove('reveal')
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = document.querySelector('.section-interactive-map')

    let alterLogoStyleDebounce = debounce(
      () => {
        let targetRect = targetElement.getBoundingClientRect();
        let sectionRect = sectionElement.getBoundingClientRect();

        let targetTop = targetRect.top + document.body.scrollTop
        let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

        let sectionTop = sectionRect.top + document.body.scrollTop
        let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

        if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
          targetElement.parentElement.classList.remove('color-logo')
          targetElement.parentElement.classList.add('white-logo')
          document.querySelector('.nav-trigger').classList.add('nav-white')
        }
      }
    )

    window.addEventListener(
      "scroll", () => {
        alterLogoStyleDebounce()
      }
    )
  }

  addUnsetTitleEvent() {
    this.infoBoxElement.addEventListener('transitionend', () => {
      if (this.unsetInfoBoxTitle) {
        this.infoBoxElement.querySelector('h6').innerHTML = ""
        this.unsetInfoBoxTitle = false
      }
    })
  }

  addSwitchOutInfoEvent() {
    this.infoBoxElement.addEventListener('transitionend', () => {
      if (this.infoBoxSwitchOut) {
        this.infoBoxSwitchOut = false
        this.setInfoBoxAttributes()
      }
    })
  }

  animateFadeIn(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('faded-in');
        this.fadeInObserver.unobserve(entry.target);
      }
    });
  }

  handleNoIntersectionObserverSupport () {
    let {markers} = this;
    let {labelLayer} = this;

    if (labelLayer) {
      labelLayer.classList.add('faded-in');
    }

    if (markers) {
      markers[0].classList.add('faded-in');
    }
  }

  infoBoxClose(event) {
    event.preventDefault();

    this.infoBoxElement.classList.remove('reveal')
    this.unsetInfoBoxTitle = true

    if (this.isMobileOrTablet()) {
      let siblings = getSiblings(this.element).concat(
        document.querySelector('footer'),
        document.querySelector('.page-nav')
      )

      siblings.forEach((siblingElement) => {
        siblingElement.style.removeProperty('transform')
      })
    }
  }

  infoBoxOpen(event) {
    event.preventDefault();

    this.infoBoxElement = document.querySelector('.section-interactive-map__info-box-container')
    this.pointerElement = event.currentTarget

    if (this.isValidPointer() && this.isNewPointerInformation()) {
      this.toggleInfoBox();
    }
  }


  initAnimations() {
    if (this.intersectionObserverIsSupported()) {
      setTimeout(() => {
        this.setFadeInAnimations();
      }, 500)
    } else {
      this.handleNoIntersectionObserverSupport();
    }
  }


  initPanZoom() {
    if (this.isDesktop()) {
      this.panzoom = Panzoom(this.containerTarget, {
        contain: 'outside',
        panOnlyWhenZoomed: true
      });
    }
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isNewPointerInformation() {
    let title = this.pointerElement.querySelector("[data-point-title]").dataset.pointTitle.trim()

    return (title !== this.infoBoxElement.querySelector('h6').innerHTML.trim()) || title === 'Les Villas'
  }

  isValidPointer() {
    return !!this.pointerElement.querySelector("[data-point-image]").dataset.pointImage.trim() &&
      !!this.pointerElement.querySelector("[data-point-description]").dataset.pointDescription.trim() &&
      !!this.pointerElement.querySelector("[data-point-title]").dataset.pointTitle.trim() &&
      !!this.pointerElement.querySelector("[data-point-url]").dataset.pointUrl.trim()
  }

  isInfoBoxOpen() {
    return this.infoBoxElement.classList.contains('reveal')
  }

  isMobileOrTablet() {
    return this.clientWidth < this.tabletBreakPoint
  }

  isMobile() {
    return this.clientWidth < this.mobileBreakPoint
  }

  isTablet() {
    return this.clientWidth < this.tabletBreakPoint &&
      this.clientWidth >= this.mobileBreakPoint
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  isTabletOrDesktop() {
    return this.clientWidth >= this.mobileBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  revealInfoBox() {
    if (this.isMobile()) {
      let siblings = getSiblings(this.element).concat(
        document.querySelector('footer'),
        document.querySelector('.page-nav')
      )

      siblings.forEach((siblingElement) => {
        siblingElement.style.setProperty('transform', 'translateY(362px)')
      })
    }

    if (this.isTablet()) {
      let siblings = getSiblings(this.element).concat(
        document.querySelector('footer'),
        document.querySelector('.page-nav')
      )

      siblings.forEach((siblingElement) => {
        siblingElement.style.setProperty('transform', 'translateY(700px)')
      })
    }

    this.infoBoxElement.classList.add('reveal')

    this.setInfoBoxAttributes()
  }

  revealInfoBoxCallback() {
    this.infoBoxElement.classList.add('reveal')
  }

  setFadeInAnimations() {
    if (this.isTabletOrDesktop()) {
      let {markers} = this;
      let {labelLayer} = this;
      let options = {
        root: null,
        rootMargin:'0px',
        threshold: .1
      };

      if (labelLayer) {
        this.fadeInObserver = new IntersectionObserver(this.animateFadeIn.bind(this), options);
        this.fadeInObserver.observe(labelLayer);
      }

      if (markers.length > 0) {
        this.fadeInObserver = new IntersectionObserver(this.animateFadeIn.bind(this), options);
        for (let marker of markers) {
          this.fadeInObserver.observe(marker);
        }
      }
    }

  }

  setInfoBoxAttributes() {
    let imageSrc = this.pointerElement.querySelector("[data-point-image]").dataset.pointImage;
    let description = this.pointerElement.querySelector("[data-point-description]").dataset.pointDescription
    let title = this.pointerElement.querySelector("[data-point-title]").dataset.pointTitle
    let url = new URL(this.pointerElement.querySelector("[data-point-url]").dataset.pointUrl)

    let imageElement = this.infoBoxElement.querySelector('img')

    this.infoBoxElement.querySelector('img').setAttribute('src', imageSrc)
    this.infoBoxElement.querySelector('p').innerHTML = description
    this.infoBoxElement.querySelector('h6').innerHTML = title

    if (url.pathname.length > 4) {
      this.infoBoxElement.querySelector('.js-pointer-view-link').setAttribute('style', 'display: flex;')
      this.infoBoxElement.querySelector('.js-pointer-view-link').href = url.href
    } else {
      this.infoBoxElement.querySelector('.js-pointer-view-link').setAttribute('style', 'display: none;')
    }

    imageElement.addEventListener('load', this.revealInfoBoxCallback.bind(this))
  }


  toggleInfoBox() {
    this.addUnsetTitleEvent();

    if (this.isInfoBoxOpen()) {
      this.addSwitchOutInfoEvent();
      this.closeAndRevealInfoBox()
    } else {
      this.revealInfoBox()
    }
  }

  zoomIn() {
    if (this.isDesktop()) {
      this.panzoom.zoomIn({animate: false});
    } else {
      let classList = this.mobileZoomContainerTarget.classList

      for (let level of this.zoomingInLevels) {
        if (!classList.contains(level)) {
          this.mobileZoomContainerTarget.classList.add(level)
          break
        }
      }
    }

  }

  zoomOut() {
    if (this.isDesktop()) {
      this.panzoom.zoomOut({animate: false, minScale: 1});
    } else {
      let classList = this.mobileZoomContainerTarget.classList

      for (let level of this.zoomingOutLevels) {
        if (classList.contains(level)) {
          this.mobileZoomContainerTarget.classList.remove(level)
          break
        }
      }
    }
  }

}
