import { Controller } from "stimulus";

import { loadRequestHtmlBy } from "init/ajax";

import { AnimateIn } from "config/animateIn";

import debounce from 'config/debounce.js';

import lozad from 'lozad'

export default class extends Controller {
  static targets = [ "embeds" ]

  connect() {
    this.tabletBreakPoint = 1023
    this.clientWidth = document.documentElement.clientWidth

    // this.initLazyLoad()
    this.initLogoAnimation()

    this.observer = lozad();
    this.observer.observe();
  }


  initLazyLoad() {
    this.observer.observe();
  }

  intersectionObserverIsSupported() {
    return !this.noIntersectionObserverSupport();
  }

  isDesktop() {
    return this.clientWidth >= this.tabletBreakPoint
  }

  noIntersectionObserverSupport() {
    return !('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }

  setLogoAnimation() {
    let targetElement = document.querySelector('.logo')
    let sectionElement = this.element

    if (getComputedStyle(this.element, null).display !== 'none') {
      let alterLogoStyleDebounce = debounce(
        () => {
          let targetRect = targetElement.getBoundingClientRect();
          let sectionRect = sectionElement.getBoundingClientRect();

          let targetTop = targetRect.top + document.body.scrollTop
          let targetHeight = parseFloat(getComputedStyle(targetElement, null).height.replace("px", ""))

          let sectionTop = sectionRect.top + document.body.scrollTop
          let sectionHeight = parseFloat(getComputedStyle(sectionElement, null).height.replace("px", ""))

          if ((targetTop + targetHeight) < (sectionTop + sectionHeight) && (targetTop + targetHeight > sectionTop)) {
            targetElement.parentElement.classList.remove('color-logo')
            targetElement.parentElement.classList.add('white-logo')
            document.querySelector('.nav-trigger').classList.remove('nav-white')
          }
        }
      )

      window.addEventListener(
        "scroll", () => {
          alterLogoStyleDebounce()
        }
      )
    }
  }

  initLogoAnimation() {
    if (this.intersectionObserverIsSupported() && this.isDesktop()) {
      setTimeout(() => {
        this.setLogoAnimation();
      }, 500)
    }
  }

  async toggleLatest(event) {
    event.preventDefault()
    let responseHTML = await loadRequestHtmlBy(`${window.location.pathname}?sort=desc`)

    this.updateEmbedsMarkup(responseHTML)
  }

  async toggleOldest(event) {
    event.preventDefault()
    let responseHTML = await loadRequestHtmlBy(`${window.location.pathname}?sort=asc`)

    this.updateEmbedsMarkup(responseHTML)
  }

  updateEmbedsMarkup(responseHTML) {
    this.embedsTarget.replaceWith(responseHTML.querySelector('[data-target="magazine-embeds.embeds"]'))

    let animate = new AnimateIn('[data-target="magazine-embeds.embeds"]');
    animate.init();
    this.initLazyLoad()
  }
}

