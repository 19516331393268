import { Notifier } from "@airbrake/browser";
import application from "stimulus_application";

const env = process.env.RAILS_ENV;
if (env !== "development" && env !== "test") {
  const airbrake = new Notifier({
    projectId: 257351,
    projectKey: "637c4899f09b8fd83662700081b1013c",
    environment: env
  });

  const defaultErrorHandler = application.handleError;

  const airbrakeHandler = err => {
    defaultErrorHandler(err);
    airbrake.notify(err);
  };

  application.handleError = airbrakeHandler;
}
